/* ================================= */
/* Root Variables and Utility Styles */
/* ================================= */

html {
  --section-background-color: linear-gradient(
          to bottom left,
          rgba(16, 16, 17, 0.58),
          rgba(0, 17, 49, 0.9)
  );

  --image-gradient: linear-gradient(
          to bottom,
          rgba(17, 16, 17, 0.9),
          rgba(0, 17, 49, 0.67)
  );

  --imp-text-color: #b451f6;
  --preloader-bg: #0c0513;
  --gradient-text-start: #f8f8ff;
  --gradient-text-mid: #c7b8c8;
  --gradient-text-end: #b19bbd;
  --scrollbar-track: #503a19;
  --scrollbar-thumb: rgba(216, 200, 121, 0.96);
  --scrollbar-thumb-hover: rgba(130, 191, 235, 0.91);

  --navbar-toggler: #50b5f4;
  --navbar-underline: #5ba3f5;
  --navbar-brand-color: rgb(250, 250, 250);

  --mobile-navbar-bg: #181a27;

  --sticky-bg-rgba: rgba(26, 29, 46, 0.66);
  --sticky-shadow-rgba: rgba(9, 5, 29, 0.171);

  --white: #ffffff;
  --footer-bg: rgb(4, 8, 22);
  --blockquote-footer-color: #8891c0;

  --btn-primary-bg: #364686;
  --btn-primary-hover-bg: rgba(32, 59, 197, 0.84);

  --typewriter-wrapper-color: #7f6adf;
  --typewriter-cursor-color: #ac00f8;
  --main-name-color: #8400ff;
  --icon-color: #0c2886;

  --resume-item-border: #495ca8;
  --resume-item-border-before: #4956a8;

  --home-icon-before: #18257a;
  --home-icon-hover-before: #1f3795;
  --home-icon-hover: #20359e;

  --project-card-shadow: rgb(1, 105, 190);
  --project-card-hover-shadow: rgb(140, 0, 255);
  --blog-card-shadow: rgba(77, 91, 161, 0.46);
  --blog-card-hover-shadow: rgba(88, 112, 173, 0.65);
}

.purple {
  color: var(--imp-text-color) !important;
  text-shadow: 0 0 6px var(--imp-text-color);
}

button:focus {
  box-shadow: none !important;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* =============== */
/* Preloader Styles */
/* =============== */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: var(--preloader-bg);
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

/* ================= */
/* Typography Effects */
/* ================= */

.gradient-text {
  font-weight: 700;
  font-size: 2.25rem;
  padding-bottom: 0.75rem;
  background: linear-gradient(
          to bottom,
          var(--gradient-text-start) 0%,
          var(--gradient-text-mid) 40%,
          var(--gradient-text-end) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.neon-text {
  color: var(--imp-text-color);
  font-weight: 500;
  letter-spacing: 0.1em;
  text-shadow:
          0 0 25px var(--imp-text-color),
          0 0 15px var(--imp-text-color),
          0 0 20px var(--imp-text-color);
  padding: 0 -2rem;
}

/* ================ */
/* Divider Component */
/* ================ */

.neon-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
  margin: 2rem 0;
}

.neon-divider::before,
.neon-divider::after {
  content: "";
  flex: 1;
  height: 2px;
  background: linear-gradient(to right, transparent, var(--imp-text-color), transparent);
  opacity: 0.8;
}

/* ================= */
/* Scrollbar Styling */
/* ================= */

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
  border-radius: 12px;
}

/* ============== */
/* Navbar Section */
/* ============== */

.sticky {
  background-color: var(--sticky-bg-rgba) !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px var(--sticky-shadow-rgba) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: var(--navbar-toggler) !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

.navbar-brand {
  color: var(--navbar-brand-color) !important;
}

.logo {
  height: 1.8em !important;
  width: 1.8em !important;
}

.navbar-nav .nav-link {
  color: var(--white) !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: var(--navbar-underline);
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: var(--mobile-navbar-bg) !important;
  }

  .navbar-nav {
    margin-top: 10px;
  }

  .navbar-nav .nav-link {
    color: var(--white);
    padding: 10px 20px;
  }
}

/* ============== */
/* Home Section */
/* ============== */

.wave {
  animation-name: wave-animation;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: 1;
  background-image: var(--image-gradient);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  scale: inherit;
}

.video-wrapper {
  aspect-ratio: 16 / 9;
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.video-wrapper iframe,
.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.steam-iframe-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 225px;
}

.steam-iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-content {
  padding: 5rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.video-container {
  position: relative;
}

.video-slideshow {
  position: relative;
  right: 30%;
  bottom: 30%;
  transform: scale(0.3, 0.3);
  transition: all 0.1s ease-in-out;
}

@media (max-width: 768px) {
  .video-slideshow {
    transform: scale(0.3, 0.3);
  }
}

.home-video-content {
  padding-top: 80px !important;
}

.heading {
  font-size: 2.4em !important;
}

.heading-name {
  font-size: 2.5em !important;
}

.main-name {
  color: var(--main-name-color);
  text-shadow: 0 0 15px var(--main-name-color);
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: var(--typewriter-wrapper-color) !important;
  font-weight: 600 !important;
}

.Typewriter__cursor {
  font-size: 2.4em !important;
  color: var(--typewriter-cursor-color) !important;
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

/* ========================== */
/* Home About and Social Area */
/* ========================== */

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: var(--white) !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: var(--white) !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--home-icon-before);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--home-icon-hover-before);
}

.home-social-icons:hover {
  color: var(--home-icon-hover);
  box-shadow: 0 0 5px var(--home-icon-hover);
  text-shadow: 0 0 2px var(--home-icon-hover);
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: var(--icon-color) !important;
}

/* ============ */
/* Footer Styles */
/* ============ */

.footer {
  background-color: var(--footer-bg);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright,
  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: var(--white) !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: var(--blockquote-footer-color) !important;
}

/* ================ */
/* Projects Section */
/* ================ */

.project-section {
  position: relative !important;
  padding: 5rem 0 2rem !important;
  background-image: var(--section-background-color) !important;
}

.project-card,
.blog-card {
  padding: 50px 25px !important;
  height: auto !important;
}

.project-card-view,
.blog-card-view {
  background-color: transparent !important;
  color: var(--white) !important;
  height: 100% !important;
  transition: all 0.5s ease 0s !important;
}

.project-card-view {
  box-shadow: 1px 4px 5px 3px var(--project-card-shadow) !important;
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 1px 4px 4px 5px var(--project-card-hover-shadow) !important;
}

.blog-card-view {
  box-shadow: 0 3px 3px 2px var(--blog-card-shadow) !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px var(--blog-card-hover-shadow) !important;
}

.blog-link {
  color: var(--white) !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 1.0 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: var(--white) !important;
  background-color: var(--btn-primary-bg) !important;
  border-color: var(--btn-primary-bg) !important;
}

.btn-primary:hover {
  background-color: var(--btn-primary-hover-bg) !important;
  border-color: var(--btn-primary-hover-bg) !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.project-heading {
  color: var(--white) !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* ================ */
/* About Section */
/* ================ */

.about-section,
.project-page {
  position: relative !important;
  padding: 5rem 0 2rem !important;
  background-image: var(--section-background-color) !important;
  color: var(--white) !important;
}

.project-page {
  padding-top: 100px !important;
  padding-bottom: 30px !important;
}

.techstack-icons,
.techstack-icons-small {
  margin: 5px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1px solid rgba(137, 156, 230, 0.64) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 10px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(4, 37, 168, 0.13) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

.techstack-icons {
  font-size: 4em !important;
}

.techstack-icons-small {
  font-size: 2.5em !important;
}

@media (max-width: 767px) {
  .techstack-icons {
    margin: 10px !important;
  }
}

.techstack-icons:hover {
  transform: scale(1.05) !important;
  border: 1px solid #b451f6 !important;
  box-shadow: 4px 5px 4px 3px #250050 !important;
}

.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  background-color: transparent !important;
  color: var(--white) !important;
}

.icon-label,
.icon-label-small {
  margin-top: 5px;
  font-weight: bold;
  text-align: center;
}

.icon-label {
  font-size: 14px;
}

.icon-label-small {
  font-size: 10px;
}

.about-activity,
.bullet-list {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

.bullet-list {
  padding: 10px 0 30px;
  margin: 0;
}

.bullet-item {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 0.1rem;
}

.bullet-icon {
  margin-top: 0.25rem;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

/* ================= */
/* Resume Section */
/* ================= */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: var(--white) !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left,
  .resume-right {
    padding: 0 15px !important;
  }
}

.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding: 30px 0;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid var(--resume-item-border);
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: rgba(52, 63, 121, 0.36);
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: var(--white);
  border: 2px solid var(--resume-item-border-before);
}

/* ============== */
/* Interactive Buttons */
/* ============== */

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: rgba(76, 98, 206, 0.37) !important;
  border-color: rgba(76, 98, 206, 0.37) !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: rgba(77, 104, 211, 0.53) !important;
  border-color: rgba(77, 104, 211, 0.53) !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}

@keyframes likeAnimation {
  0% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: rgba(76, 93, 206, 0.37) !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: rgba(76, 93, 206, 0.37) !important;
  border-color: rgba(76, 93, 206, 0.37) !important;
}

.fork-btn-inner::after {
  display: none !important;
}

/* ============ */
/* Section Spacing */
/* ============ */

.section-heading {
  font-size: 2em;
}

section {
  margin-bottom: 80px;
}
